import React, { useEffect, useState } from "react";
import client from "../client.js";
import { Image } from "react-datocms"

const Authors = () => {
  const [isFetching, setIsFetching] = useState(false);

  return (
    <p>from about</p>
  );
};

const query = `
`;

export default Authors;
